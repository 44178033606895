//トップページ　キービジュアルスライダー
export function idxFunc() {
  let indexPage = $('#indexTop').length;

  if(indexPage){
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    let ytPlayer,videoID;

    var loadYtPlayerFunc = function(videoID) {
      ytPlayer = new YT.Player(
        "youtubePlayer",
        {
          videoId: videoID,
          playerVars: {
            autoplay: 1,
            loop: 1,
            modestbranding: 1,
            widgetid: 1,
            playlist: videoID,
            playsinline: 1,
            showinfo: 0,
            fs: 0,
          },
          events: {
            onReady: onPlayerReady,
          },
        }
      );
    
      function onPlayerReady(event) {
      event.target.mute();
      event.target.playVideo();
      }
    }
    
    window.onYouTubeIframeAPIReady = function () {
      loadYtPlayerFunc('PVQgUJRfQZk')
    }
  }
}